import DashboardRoutes from "@/modules/dashboard/routes";
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: async () => await import('@/layout/MainLayout.vue'),
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: async () =>
          await import('@/modules/dashboard/views/PublicDashboard.vue')
      },
      {
        path: '/product/pentest',
        name: 'pentest',
        component: async () => await import('@/modules/dashboard/components/PentestService.vue')
      },
      {
        path: '/product/bug-bounty',
        name: 'bugbounty',
        component: async () => await import('@/modules/dashboard/components/BugBounty.vue')
      },
      {
        path: '/solution/finance',
        name: 'finance',
        component: async () => await import('@/modules/dashboard/components/FinanceSolution.vue')
      },
      {
        path: '/solution/automobile',
        name: 'automobile',
        component: async () => await import('@/modules/dashboard/components/AutoSolution.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        component: async () => await import('@/modules/dashboard/components/ContactUs.vue')
      },
      {
        path: '/terms-and-conditions',
        name: 'tos',
        component: async () => await import('@/modules/legal/views/ToS.vue')
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: async () => await import('@/modules/legal/views/PrivacyDocuments.vue')
      },
      {
        path: '/code-of-conduct',
        name: 'coc',
        component: async () => await import('@/modules/legal/views/CoC.vue')
      },
      {
        path: '/privacy-revelation-policy',
        name: 'prp',
        component: async () =>
          await import('@/modules/legal/views/InfoDisclosurePolicy.vue')
      },
      {
        path: '/programs',
        name: 'programs',
        component: async () => await import('@/modules/program/views/ProgramPage.vue')
      },
      {
        path: '/program/:uuid',
        name: 'program-detail',
        component: async () =>
          await import('@/modules/program/views/ProgramDetail.vue')
      },
      {
        path: "/403",
        name: "403",
        component: () => import("@/components/Error403.vue"),
      },
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/components/Error404.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
      },
    ]
  },
  // Auth
  {
    path: "/sign-in",
    component: () => import("@/components/page-layouts/DarkFooter.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          authRequired: false,
          actionRequired: "none",
          orActionsRequired: [],
        },
      },
    ],
  },
  {
    path: "/password/reset",
    component: () => import("@/components/page-layouts/DarkFooter.vue"),
    children: [
      {
        path: "/password/reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          authRequired: false,
          actionRequired: "none",
          orActionsRequired: [],
        },
      },
      {
        path: "/password/reset/:token",
        name: "change-password",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/ChangePassword.vue"
          ),
        meta: {
          authRequired: false,
          actionRequired: "none",
          orActionsRequired: [],
        },
      },
    ],
  },
  {
    //Not implemented yet
    path: '/redir',
    name: 'redir',
    component: async () =>
      await import('@/modules/dashboard/views/Redirector.vue'),
    meta: {
      authRequired: true,
      actionRequired: "none",
      orActionsRequired: [],
    },
  },
  ...DashboardRoutes,
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload()
  }
}) // onError

export default router
